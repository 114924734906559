
var slider = tns({
  container: '#about-quotes',
  items: 1,
  slideBy: 'page',
  autoplay: 'start',
  loop: false,
  autoHeight: true,
  touch: true,
  mouseDrag: true,
  controlsContainer: '#quote-slider-controls',
  navContainer: '#quote-slider-nav',
  autoplayText: ['','']
});

var prev_no = document.querySelector('#prev-no');
var prev_yes = document.querySelector('#prev-yes');

var next_no = document.querySelector('#next-no');
var next_yes = document.querySelector('#next-yes');


slider.events.on('transitionEnd', function (info, eventName) {
  if( (info.displayIndex < info.slideCount) && (info.displayIndex === 1)){
    next_yes.classList.add('active-control-quote-slider');
    next_no.classList.remove('active-control-quote-slider');
    prev_no.classList.add('active-control-quote-slider');
    prev_yes.classList.remove('active-control-quote-slider');
  } else {
    next_yes.classList.add('active-control-quote-slider');
    next_no.classList.remove('active-control-quote-slider');
    prev_no.classList.remove('active-control-quote-slider');
    prev_yes.classList.add('active-control-quote-slider');
  }
  if(info.displayIndex === info.slideCount){
    next_no.classList.add('active-control-quote-slider');
    next_yes.classList.remove('active-control-quote-slider');
    prev_no.classList.remove('active-control-quote-slider');
    prev_yes.classList.add('active-control-quote-slider');
  }
});
