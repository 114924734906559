
class quoteSlider extends HTMLElement {
  constructor(){
    super()

    
    this.prev_no = this.querySelector('#prev-no');
    this.prev_yes = this.querySelector('#prev-yes');
    
    this.next_no = this.querySelector('#next-no');
    this.next_yes = this.querySelector('#next-yes');
  }

  connectedCallback(){
    this.slider = tns({
      container: this.querySelector('#about-quotes'),
      items: 1,
      slideBy: 'page',
      autoplay: 'start',
      loop: false,
      autoHeight: true,
      touch: true,
      mouseDrag: true,
      controlsContainer: this.querySelector('#quote-slider-controls'),
      navContainer: this.querySelector('#quote-slider-nav'),
      autoplayText: ['','']
    });
    
    this.slider.events.on('transitionEnd', this.sliderTransition.bind(this));
  }

  sliderTransition(info, eventName) {
    if( (info.displayIndex < info.slideCount) && (info.displayIndex === 1)){
      this.next_yes.classList.add('active-control-quote-slider');
      this.next_no.classList.remove('active-control-quote-slider');
      this.prev_no.classList.add('active-control-quote-slider');
      this.prev_yes.classList.remove('active-control-quote-slider');
    } else {
      this.next_yes.classList.add('active-control-quote-slider');
      this.next_no.classList.remove('active-control-quote-slider');
      this.prev_no.classList.remove('active-control-quote-slider');
      this.prev_yes.classList.add('active-control-quote-slider');
    }
    if(info.displayIndex === info.slideCount){
      this.next_no.classList.add('active-control-quote-slider');
      this.next_yes.classList.remove('active-control-quote-slider');
      this.prev_no.classList.remove('active-control-quote-slider');
      this.prev_yes.classList.add('active-control-quote-slider');
    }
  }
}

customElements.define('quote-slider', quoteSlider);